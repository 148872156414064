var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.courseExpenses,"search":_vm.search,"item-key":"id","sort-by":"inserted","sort-desc":true,"show-select":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Reise / Stipend")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i reise og stipend","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.selectedExpenses.length > 0)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Godkjenn valgte utlegg","icon":"mdi-file-sign","dataCy":"sendButton"},on:{"handleClick":_vm.approveSelectedExpenses}}):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":("displayExpense-" + (item.id))},on:{"click":function($event){return _vm.displayExpenseEntry(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-open-in-new")])]}}],null,true)},[_c('span',[_vm._v("Vis utlegg")])])]}},{key:"item.inserted",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDate(value))+" ")]}},{key:"item.amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.sum",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(value))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getCourseParticipantExpenseStatusByStatus(value))+" ")]}}],null,true),model:{value:(_vm.selectedExpenses),callback:function ($$v) {_vm.selectedExpenses=$$v},expression:"selectedExpenses"}})}
var staticRenderFns = []

export { render, staticRenderFns }