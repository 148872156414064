












































import { api } from "@/api/api";
import { ApiGetExpenseDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { CourseParticipantExpenseStatus } from "@/shared/enums/CourseParticipantExpenseStatus.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import {
  getCourseParticipantExpenseStatusByStatus,
  updateExpenseEntry,
} from "@/shared/helpers/courseParticipantExpeseHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseExpensesPage",
  components: { BaseTableFiltered, BaseTooltipIconButton },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();

    const search = ref("");
    const courseExpenses = ref<ApiGetExpenseDto[]>([]);
    const selectedExpenses = ref<ApiGetExpenseDto[]>([]);

    const displayExpenseEntry = (id: string) => {
      router.push({
        name: SingleCourseRouteNames.CourseExpenseDetails,
        params: {
          id: route.params.id,
          expenseId: id,
        },
      });
    };

    const approveExpenses = async (expenses: ApiGetExpenseDto[]) => {
      if (!expenses.length) {
        return;
      }
      await globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all(
          expenses.map((expense) =>
            updateExpenseEntry(
              store,
              expense,
              CourseParticipantExpenseStatus.Validated,
              `Utlegg ${expense.id} oppdatert med status godkjent`
            )
          )
        );
      });
    };

    const loadExpenses = async () => {
      const expenses = (
        await api.courseparticipantexpense.getCourseParticipantExpenseAsync({ CourseId: +route.params.id })
      ).data;
      courseExpenses.value = expenses.map((expense) => ({
        ...expense,
        isSelectable: expense.status === CourseParticipantExpenseStatus.Draft,
      }));
    };

    onParamChange(loadExpenses);

    const approveSelectedExpenses = async () => {
      await approveExpenses(selectedExpenses.value);
      selectedExpenses.value = [];
      await loadExpenses();
    };

    return {
      headers,
      search,
      courseExpenses,
      selectedExpenses,
      formatCurrency,
      formatLocalizedDate,
      displayExpenseEntry,
      approveSelectedExpenses,
      getCourseParticipantExpenseStatusByStatus,
    };
  },
});

const headers = [
  { text: "Dato", value: "inserted" },
  { text: "Student-ID", value: "courseParticipant.userFullName" },
  { text: "Kostnadstype", value: "cost.name" },
  { text: "Antall", value: "quantity" },
  { text: "Beløp", value: "amount" },
  { text: "Sum", value: "sum" },
  { text: "Kommentar", value: "description" },
  { text: "Status", value: "status" },
  { text: "Handlinger", value: "actions" },
];
